// src/Notification.ts
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('app-notification')
export class AppNotification extends LitElement {
    @property({ type: String }) message = '';
    // 'info', 'success', 'error', 'warning'
    @property({ type: String }) type = 'info';

    static override styles = css`
    :host {
      display: block;
      position: fixed;
      top: 1rem;
      right: 1rem;
      padding: 1rem;
      border-radius: 5px;
      color: white;
      z-index: 1000;
      transition: opacity 0.5s ease-in-out;
    }
    :host([hidden]) {
      display: none;
    }
    .info {
      background-color: #2196f3;
    }
    .success {
      background-color: #4caf50;
    }
    .error {
      background-color: #f44336;
    }
    .warning {
      background-color: #ff9800;
    }
  `;

    override render() {
        return html`
      <div class=${this.type}>${this.message}</div>
    `;
    }
}