'use strict';

import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('hello-world-view')
export class HelloWorldView extends LitElement {
    static override styles = css`
    :host {
      display: block;
      padding: 16px;
      background-color: red;
    }
  `;

    @property()
    message = 'Hello World View';

    override render() {
        return html`<p>${this.message}</p>`;
    }
}
