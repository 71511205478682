import { Route } from '@vaadin/router';
import './views/hello-world-view';
import './views/main-layout';


export const views: Route[] = [
    {
        path: '',
        component: 'hello-world-view',
        //title: '',
    },
    {
        path: 'hello',
        component: 'hello-world-view',
        //title: 'Hello World',
    },
    {
        path: 'about',
        component: 'about-view',
        //title: 'About',
        //action: async () => await import('./views/about-view'),
    },
];

export const routes: Route[] = [
    {
        path: '',
        component: 'main-layout',
        children: [...views],
    },
];
