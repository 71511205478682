// src/index.ts
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import './app-notification';
import { Router } from '@vaadin/router';
import { routes } from './routes';
import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { RoughEase, ExpoScaleEase, SlowMo } from "gsap/EasePack";
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import {clusterApiUrl, Connection, Keypair, PublicKey, LAMPORTS_PER_SOL} from "@solana/web3.js";

const connection = new Connection(clusterApiUrl('testnet'));
const publicKey = new PublicKey(PublicKey.default);
console.log(publicKey);
console.log('lamports per sol:', LAMPORTS_PER_SOL);
// Generate a new Keypair
const keypair :Keypair = Keypair.generate();
console.log(keypair);

console.log('Connection to test net established:', connection);
console.log('Generated Keypair:', keypair);

gsap.registerPlugin(
    Flip,
    ScrollTrigger,
    Observer,
    ScrollToPlugin,
    Draggable,
    MotionPathPlugin,
    EaselPlugin,
    PixiPlugin,
    TextPlugin,
    RoughEase,
    ExpoScaleEase,
    SlowMo,
    CustomEase
);

export const router = new Router(document.querySelector('#outlet'));
if (!router.location) {
    let loc = router.location;
    console.log(router);
    console.log(loc);
    router.setRoutes(routes)
    //router.setRoutes(routes);
}
//router.setRoutes(routes);

// gsap animation
const animateElement = () => {
    gsap.to("#animatedElement", {
        duration: 1,
        x: 100,
        y: 100,
        opacity: 0.8,
    });
};

animateElement();

// popup notification
const notyf = new Notyf();
document.querySelector('#ca-address')?.addEventListener('click', () => {
    // Your copy logic here
    const textarea = document.createElement('textarea');
    textarea.value = (document.querySelector('#ca-address') as HTMLElement).innerText.trim();
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Show notification
    notyf.success('Copied CA address to clipboard');
});

// document.addEventListener('DOMContentLoaded', (event) => {
//     const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
//     const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => {
//         return new bootstrap.Tooltip(tooltipTriggerEl);
//     });
// });


// Get the button
let scrollButton :HTMLElement | null = document.getElementById("scrollToTopBtn");
console.log("scroll button is null: " + scrollButton === null);
if (scrollButton !== null){
    console.log("adding scroll button functionality");
    console.log("scroll button is null: " + scrollButton === null);

// When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {
        scrollFunction();
    };

    function scrollFunction() {
        if (scrollButton && document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollButton.style.display = "block";
        } else {
            scrollButton.style.display = "none";
        }
    }

// When the user clicks on the button, scroll to the top of the document
    function backToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    }

    if (scrollButton) {
        scrollButton.addEventListener('click', backToTop);
    }

    const animateButton = () => {
        gsap.to("#scrollToTopBtn", {
            scale: 1.2, // Scale up to 120%
            duration: 0.5, // Duration of each pulse
            ease: "power1.inOut", // Easing function for a smooth effect
            yoyo: true, // Reverses the animation for a pulsating effect
            repeat: -1 // Repeat the animation infinitely
        });
    }

    animateButton();
}